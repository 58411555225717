import React from "react"
import { graphql } from "gatsby"
import { Layout } from "@/layout"
import Img from "gatsby-image"
import styled from "styled-components"
import { useLocation } from "@reach/router"

import {
  Box,
  CTA,
  SEO,
  Width,
  Heading,
  Text,
  SmallWave,
  MentorAlert,
  ArrowAlt,
  CalComplete,
  CalIncomplete,
  Relationships,
  Grow,
  MentorCard,
  List,
  ListItem,
  Carousel,
  Slide,
  Coupon,
  VideoPlayer,
} from "@/components/index"
import { color } from "@/theme/index"
import queryString from "query-string"

export default function Fellowship({ data }) {
  const location = useLocation()
  const urlParameters =
    typeof window !== "undefined" ? queryString.parseUrl(location.search) : ""

  // TODO TAKE ME OUT AFTER MARCH 2021
  if (!urlParameters.query?.offer) {
    if (typeof window !== "undefined")
      window.location.href = "/fellowship?offer=promo"
  }
  return (
    <Layout
      headerType="checkout"
      headerTheme="light"
      footerTheme="light"
      squeeze={true}
    >
      <SEO
        title="Sonora Fellowship Subscription"
        description="Sonora pairs world-class mentors with intermediate guitar students in an intensive 3-month program to help them find their breakthrough on guitar."
        noindex={true}
      />
      <Box
        height="100%"
        position="relative"
        zIndex="3"
        top="0"
        width="100%"
        pt="9.6rem"
      >
        {/* Accelerated Learning */}
        <Box variant="slopeBottomLeft">
          <Width pt="8rem" pb="3rem">
            <Box
              width={["100%", 10 / 12, 8 / 12, 6 / 12]}
              mx="auto"
              mb="5rem"
              textAlign="center"
            >
              <SmallWave display="block" mx="auto" mb="1.2rem" />
              <Heading mb="3.6rem">
                Continue the Journey with Sonora Fellowship Subscription
              </Heading>
              <Text mt="3.6rem">
                Sonora's Fellowship Subscription Program provides continued
                unlimited 1-on-1 access to a dedicated mentor, daily video
                feedback, weekly Masterclasses, facilitated practice sessions,
                and more.
              </Text>
            </Box>
            <Box display={["block", null, "flex", null]}>
              <Box
                width={["100%", "80%", 4 / 12, null]}
                p="2.4rem"
                borderRadius=".6rem"
                textAlign="center"
                mb={["2.4rem", null, "0"]}
                bg="#FFF7F4"
                ml={["auto", null, 0, null]}
                mr={["auto", null, "1.8rem", null]}
              >
                <Box
                  display="flex"
                  mb="1.8rem"
                  alignItems="center"
                  justifyContent={["center", null, null]}
                >
                  <Grow mr="0.8rem" height={30} width={30} />
                  <Text fontWeight="bold" color="amethyst">
                    Masterclasses
                  </Text>
                </Box>
                <Text level={2}>
                  We curate some of the greatest guitarists on earth to teach
                  masterclasses every Monday evening.
                </Text>
              </Box>
              <Box
                width={["100%", "80%", 4 / 12, null]}
                p="2.4rem"
                textAlign="center"
                mb={["2.4rem", null, "0"]}
                bg={color.opal}
                borderRadius=".6rem"
                ml={["auto", null, 0, null]}
                mr={["auto", null, "1.8rem", null]}
              >
                <Box
                  display="flex"
                  mb="1.8rem"
                  alignItems="center"
                  justifyContent={["center", null, null]}
                >
                  <Relationships height={30} width={38} mr="0.5rem" />
                  <Text fontWeight="bold" color="amethyst">
                    Practice Sessions
                  </Text>
                </Box>
                <Text level={2}>
                  Fellowship members participate in engaging facilitated group
                  practice sessions.
                </Text>
              </Box>
              <Box
                width={["100%", "80%", 4 / 12, null]}
                p="2.4rem"
                textAlign="center"
                bg={color.agate}
                borderRadius=".6rem"
                ml={["auto", null, 0, null]}
                mr={["auto", null, 0, null]}
              >
                <Box
                  display="flex"
                  mb="0.6rem"
                  alignItems="center"
                  justifyContent={["center", null, null]}
                >
                  <MentorAlert mr="0.8rem" height={40} width={41} />
                  <Text fontWeight="bold" color="amethyst">
                    Worldclass Mentorship
                  </Text>
                </Box>
                <Text level={2}>
                  Work 1-on-1 with a mentor to apply the concepts you've been
                  learning in a creative context
                </Text>
              </Box>
            </Box>
            <Box width="100%" mt="3.6rem" mx="auto" textAlign="center">
              <Text level={1}>Interested in learning more?</Text>
              <Text level={2}>Speak with an admissions counselor</Text>
              <CTA to="/fellowship-admissions" mt="3.6rem">
                Book a call
              </CTA>
            </Box>
          </Width>
        </Box>

        {/* Modern Study */}
        <Box variant="slopeTopRight" mt="-3.6rem">
          <Width pt="3.6rem" pb="6rem">
            <Box
              width={["100%", 10 / 12, 8 / 12, 6 / 12]}
              mx="auto"
              mb="5rem"
              textAlign="center"
            >
              <SmallWave display="block" mx="auto" mb="1.2rem" />
              <Heading level="2" mb="2rem">
                A modern approach to study
              </Heading>
              <Text>
                Unlike private lessons, Sonora’s mentored students get unlimited
                access to feedback so learning and progress can happen every day
                – not just once a week.
              </Text>
            </Box>
            <Box
              display="flex"
              flexDirection={["column", null, "row", null]}
              mx="auto"
            >
              <Box
                width={["100%", 10 / 12, 5 / 12, null]}
                textAlign="center"
                mx="auto"
              >
                <Text
                  fontSize="14px"
                  fontWeight="bold"
                  color={color.tanzanite}
                  mb=".6rem"
                >
                  THE OLD WAY
                </Text>
                <Text as="h3" color="amethyst" mb="0.6rem">
                  Traditional weekly lessons
                </Text>
                <Box
                  display="flex"
                  justifyContent="center"
                  pt=".6rem"
                  pb="3rem"
                >
                  <CalComplete />
                  <CalIncomplete />
                  <CalIncomplete />
                  <CalIncomplete />
                  <CalIncomplete />
                  <CalIncomplete />
                  <CalIncomplete />
                </Box>
                <Text level="2">
                  Students met with an instructor for just an hour, once a week.
                  For the remainer of that week, students were more likely to
                  practice bad habits and progress slowly.
                </Text>
              </Box>
              <Box
                width={["100%", null, 2 / 12, null]}
                display="flex"
                alignItems="center"
                mx={["0", null, "3.6rem", null]}
                mt={["3.6rem", null, 0, null]}
                mb={["2.4rem", null, "0", null]}
              >
                <ArrowAlt
                  // eslint-disable-next-line
                  transform={["rotate(90deg)", null, "rotate(0deg)"]}
                  mx="auto"
                  stroke="#B3A8CB"
                />
              </Box>
              <Box
                width={["100%", 10 / 12, 5 / 12, null]}
                textAlign="center"
                mx="auto"
              >
                <Text
                  fontSize="14px"
                  fontWeight="bold"
                  color={color.tanzanite}
                  mb=".6rem"
                >
                  THE MODERN WAY
                </Text>
                <Text as="h3" color="amethyst" mb="0.6rem">
                  Sonora's anytime framework
                </Text>
                <Box
                  display="flex"
                  justifyContent="center"
                  pt=".6rem"
                  pb="3rem"
                >
                  <CalComplete />
                  <CalComplete />
                  <CalComplete />
                  <CalComplete />
                  <CalComplete />
                  <CalComplete />
                  <CalComplete />
                </Box>
                <Text level="2">
                  Students get unlimited access to a mentor, 7 days a week. Your
                  mentor works with you to develop a customized plan for
                  achieving your creative goals.
                </Text>
              </Box>
            </Box>
            <Box width="100%" mx="auto" textAlign="center">
              <CTA to="/fellowship-admissions" mt="3.6rem">
                Book a call
              </CTA>
            </Box>
          </Width>
        </Box>

        {/* Worldclass Mentorship */}
        <Box bg={color.opal_hover} variant="slopeTopRight" mt="-3.6rem">
          <Width
            display={["block", null, "flex", null]}
            pb="6rem"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Box width={["100%", null, 5 / 12, null]}>
              <SmallWave
                display="block"
                mb="1.2rem"
                mx={["auto", null, 0, null]}
              />
              <Heading
                level="2"
                mb="2rem"
                textAlign={["center", null, "left", null]}
              >
                Monday Masterclasses
              </Heading>
              <Text
                mb="2.4rem"
                textAlign={["center", null, "left", null]}
                width={["100%", "80%", "100%", null]}
                mx={["auto", null, 0, null]}
              >
                We curate some of the top players in the world to come and speak
                on a wide variety of topics.
              </Text>
              <Box
                width={["100%", "60%", "100%", null]}
                mx={["auto", null, 0, null]}
              >
                <List bullets="checkmark">
                  <ListItem>
                    <span>
                      <strong>The breadth and depth</strong> of topics covered
                      means you're guaranteed to expand your understanding of
                      what's possible with the tools you've been learning.
                    </span>
                  </ListItem>

                  <ListItem>
                    <span>
                      <strong>Small class sizes </strong> mean you get face to
                      face with the greats, each week.
                    </span>
                  </ListItem>
                  <ListItem>
                    <span>
                      <strong>
                        Unlimited access to our growing back-catalog of
                        masterclasses
                      </strong>{" "}
                      allows you to watch whenever you like.
                    </span>
                  </ListItem>
                </List>
                <CTA to="/fellowship-admissions" mt="3.6rem" mb="2em">
                  Book a call
                </CTA>
              </Box>
            </Box>

            <Box
              width={["100%", "80%", 6 / 12, null]}
              px="2.4rem"
              pb="2.2rem"
              bg="#F8FAFD"
              borderRadius="0.6rem"
            >
              <Carousel>
                {/* slide 01 */}
                <Slide pt="2.4rem" pb="4.4rem" debug="green">
                  <VideoPlayer
                    url="https://vimeo.com/507366678/100237324d"
                    mb="1.8rem"
                    width="auto"
                    maxWidth="auto"
                  />
                  <Heading level="2">Adam Levy</Heading>
                  <Text>Improvisation Masterclass</Text>
                  <Text level={2} my="1.8rem">
                    Brooklyn-based Adam Levy has released more than a dozen
                    albums of his own, and is featured on recordings by Norah
                    Jones, Vulfpeck, Ani DiFranco, Allen Toussaint, and other
                    renowned artists.
                  </Text>
                </Slide>
                {/* slide 02 */}
                <Slide pt="2.4rem" pb="4.4rem" debug="green">
                  <VideoPlayer
                    mb="1.8rem"
                    width="auto"
                    maxWidth="auto"
                    url="https://vimeo.com/507366492/188b6ebbec"
                  />
                  <Heading level="2">Amanda Monaco</Heading>
                  <Text>Becoming a more melodic player</Text>
                  <Text level={2} my="1.8rem">
                    Amanda is an Associate Professor at Berklee College of Music
                    where she teaches private instruction, labs and guitar
                    ensembles. and has performed with artists such as Milt
                    Hinton, Steve Wilson, Rufus Reid, and more.
                  </Text>
                </Slide>
                {/* slide 03 */}
                <Slide pt="2.4rem" pb="4.4rem" debug="green">
                  <VideoPlayer
                    url="https://vimeo.com/507366473/2ae1cf262c"
                    mb="1.8rem"
                    width="auto"
                    maxWidth="auto"
                  />
                  <Heading level="2">Josh Sklair</Heading>
                  <Text>Play guitar but think like a drummer</Text>
                  <Text level={2} my="1.8rem">
                    Josh Sklair is a two-time Grammy Award winner, honored
                    during his 20+ years collaboration as band leader,
                    guitarist, arranger and co-writer with American Music icon
                    Etta James.
                  </Text>
                </Slide>

                {/* slide 04 */}
                <Slide pt="2.4rem" pb="4.4rem" debug="green">
                  <VideoPlayer
                    url="https://vimeo.com/507366444/3784e5eec4"
                    mb="1.8rem"
                    width="auto"
                    maxWidth="auto"
                  />
                  <Heading level="2">Seth Rosenbloom</Heading>
                  <Text>How to use and learn licks</Text>
                  <Text level={2} my="1.8rem">
                    Seth Rosenbloom is an accomplished blues guitarist with a
                    remarkable sensibility and an uncanny gift for distilling
                    complex topics into digestable form for all levels.
                  </Text>
                </Slide>
              </Carousel>
            </Box>
          </Width>
        </Box>
        {/* Fretboard Fluency */}
        <Box bg={color.amethyst} pb="5em" pt="6rem" variant="slopeBottomRight">
          <Width
            display={["block", null, "flex", null]}
            justifyContent="space-between"
            color={color.white}
          >
            <Box width={["100%", null, 5 / 12, null]}>
              <SmallWave
                display="block"
                mb="1.2rem"
                mx={["auto", null, 0, null]}
              />
              <Heading
                level={2}
                mb="2rem"
                textAlign={["center", null, "left", null]}
                color={color.thulite}
              >
                Learn something new, every day
              </Heading>
              <Text
                mb="2.4rem"
                textAlign={["center", null, "left", null]}
                width={["100%", "80%", "100%", null]}
                mx={["auto", null, 0, null]}
              >
                Fellowship is a great place to expand your horizons, while also
                going deep on the things you're most passionate about. Our
                evening live classes are a wellspring of musical discovery.
              </Text>
              <Box
                maxWidth={["30rem", null, "80%", null]}
                mx={["auto", null, 0, null]}
                mb={["3.6rem", null, 0, null]}
              >
                <Img
                  fluid={data.curriculumImage.childImageSharp.fluid}
                  alt="Curriculum Mastery"
                />
              </Box>
            </Box>
            <Box
              width={["100%", "80%", 6 / 12, null]}
              mx={["auto", null, 0, null]}
              textAlign={["center", null, "left", null]}
            >
              <Heading as="h4" level={3} color={color.thulite}>
                Improv Tuesdays
              </Heading>
              <Text level={2} mb="3.6rem">
                Gather with your fellow classmates Tuesday evenings and be
                guided through learning how to improvise. We will help you learn
                new improvisation vocabulary and show you how to use it in your
                playing.
              </Text>
              <Heading as="h4" level={3} color={color.thulite}>
                Standards Sundays
              </Heading>
              <Text level={2}>
                We gather on Sundays to learn a new jazz standard. Even if
                you're not a jazz player, the exercise of learning standards
                will open up a world of possibilities in your harmonic
                vocabulary and teach you how to navigate music.
              </Text>
            </Box>
          </Width>
          <Box width="100%" mx="auto" textAlign="center">
            <CTA
              to="/fellowship-admissions"
              variant="tertiary"
              mt="3.6rem"
              mb="3em"
            >
              Book a call
            </CTA>
          </Box>
        </Box>
        {/* <Box
          color="white"
          position="relative"
          zIndex="3"
          top="0"
          width="100%"
          display="flex"
          flexDirection="column"
          textAlign="center"
          backgroundImage="linear-gradient(79.88deg, #1B0646 27.59%, #5128AE 100.28%)"
          pt={["12.5rem", null, null, "7rem"]}
          pb={["4.8rem", null, null, "10rem"]}
          variant="slopeTopLeft"
          mt="-3.6em"
        >
          <Box
            width={["100%", 10 / 12, 8 / 12, 6 / 12]}
            mx="auto"
            mb="5rem"
            textAlign="center"
          >
            <SmallWave display="block" mx="auto" mb="1.2rem" />
            <Heading mb="3.6rem">Pricing Options</Heading>
            <Text mt="3.6rem">
              Join the <strong>Fellowship</strong> for weekly 1-on-1 lessons,
              <br /> or join <strong>Fellowship Light</strong> for bi-weekly
              lessons.
            </Text>
          </Box>

          
          <Box
            width={["100%", "80%", 6 / 12, null]}
            p="2.4rem"
            textAlign="center"
            bg={color.amethyst}
            borderRadius=".6rem"
            mt="1em"
            mx="auto"
            mb="2em"
          >
            <Box
              display="flex"
              mb="0.6rem"
              alignItems="center"
              justifyContent={["center", null, null]}
            >
              <Coupon mr="1rem" height={35} width={41} />
              <Text fontWeight="bold" color="white">
                LIMITED TIME ONLY
              </Text>
            </Box>
            <Text level={2} color="white">
              Subscribe this week and lock in a 30% discount for life.
            </Text>
            <h3>
                -30% off for <i>life</i>
            </h3>
          </Box>
          <Width
            display="flex"
            flexDirection={["column", null, "row", null]}
            textAlign="left"
            alignItems="center"
            flex="1"
          >
            <Box
              width={["100%", "80%", 6 / 12, null]}
              borderRadius=".6rem"
              textAlign="center"
              mb={["2.4rem", null, "0"]}
              bg="#FFF7F4"
              ml={["auto", null, 0, null]}
              mr={["auto", null, "1.8rem", null]}
            >
              <Box p="2.4rem">
                <Heading
                  mb="2rem"
                  mx={["auto", 0, null, null]}
                  color={color.tanzanite}
                  textAlign="center"
                >
                  Fellowship Light
                </Heading>
                <Box textAlign="left">
                  <List bullets="checkmark">
                    <ListItem color="tanzanite">
                      Bi-Weekly 30-minute lessons
                    </ListItem>
                    <ListItem color="tanzanite">
                      Unlimited access to a dedicated mentor
                    </ListItem>

                    <ListItem color="tanzanite">
                      Daily video feedback on your playing
                    </ListItem>
                    <ListItem color="tanzanite">
                      Live practice sessions every Tues, Thurs and Sat
                    </ListItem>
                    <ListItem color="tanzanite">
                      {" "}
                      Weekly live masterclasses w/ renowned guitarists
                    </ListItem>
                    <ListItem color="tanzanite">
                      {" "}
                      Access to our entire library of masterclasses
                    </ListItem>
                  </List>
                </Box>
                <CTA
                  variant="special"
                  to="/fellowship-admissions"
                  mb="1.2rem"
                  mt="3rem"
                >
                  Speak with Admissions
                </CTA>
              </Box>
              <Box
                backgroundImage="linear-gradient(72.5deg, #651FFF 0%, #D04556 99.51%)"
                color="white"
                py="2rem"
                borderBottomRightRadius=".6rem"
                borderBottomLeftRadius=".6rem"
              >
                  <>
                    <Box
                      display="flex"
                      mb="0.6rem"
                      alignItems="center"
                      justifyContent={["center", null]}
                    >
                      <h4>
                        <SmallNumber>$760 / 4 weeks</SmallNumber>
                      </h4>
                    </Box>
                    <Text
                      fontSize="14px"
                      fontWeight="bold"
                      color={color.white}
                      mb=".6rem"
                      textAlign="center"
                    >
                      LIMITED TIME
                    </Text>
                  </>
                <Box
                  display="flex"
                  mb="0.6rem"
                  alignItems="center"
                  justifyContent={["center", null]}
                >
                  <h4>
                    <Dollar>$ </Dollar>
                    <Number>
                      {urlParameters.query?.offer ? "530" : "760"}
                    </Number>{" "}
                    <Extra>/ 4 weeks</Extra>
                  </h4>
                </Box>
                <Text color="white" variant="small">
                  Billed in 12 week cycles
                </Text>
              </Box>
            </Box>
            <Box
              backgroundImage="linear-gradient(79.88deg, #5127AE 27.59%, #D04556 100.28%)"
              borderRadius=".8rem"
              width={["100%", "80%", 6 / 12, null]}
            >
              <Box
                width={"100%"}
                borderRadius=".6rem"
                textAlign="center"
                mb={["0rem", null, "0"]}
                bg={color.opal}
                ml={["auto", null, 0, null]}
                mr={["auto", null, "1.8rem", null]}
              >
                <Box
                  width={"100%"}
                  p="2.4rem"
                  borderRadius=".6rem"
                  textAlign="center"
                  mb={"0rem"}
                >
                  <Heading
                    mb=".8rem"
                    mx={["auto", 0, null, null]}
                    color={color.tanzanite}
                    textAlign="center"
                  >
                    Fellowship Pro
                  </Heading>
                  <Text
                    textAlign="center"
                    color={color.tanzanite}
                    fontSize="19px"
                    mb="2.3rem"
                  >
                    Twice the number of lessons
                  </Text>
                  <Box textAlign="left">
                    <List bullets="checkmark">
                      <ListItem color="tanzanite">
                        Weekly 30-minute lessons
                      </ListItem>
                      <ListItem color="tanzanite">
                        Unlimited access to a dedicated mentor
                      </ListItem>

                      <ListItem color="tanzanite">
                        Daily video feedback on your playing
                      </ListItem>
                      <ListItem color="tanzanite">
                        Live practice sessions every Tues, Thurs and Sat
                      </ListItem>
                      <ListItem color="tanzanite">
                        {" "}
                        Weekly live masterclasses w/ renowned guitarists
                      </ListItem>
                      <ListItem color="tanzanite">
                        {" "}
                        Access to our entire library of masterclasses
                      </ListItem>
                    </List>
                  </Box>
                  <CTA
                    variant="special"
                    to="/fellowship-admissions"
                    mb="1.2rem"
                    mt="3rem"
                  >
                    Speak with admissions
                  </CTA>
                </Box>
                <Box
                  backgroundImage="linear-gradient(72.5deg, #651FFF 0%, #D04556 99.51%)"
                  color="white"
                  py="2rem"
                  borderBottomRightRadius=".6rem"
                  borderBottomLeftRadius=".6rem"
                >
                    <>
                      <Box
                        display="flex"
                        mb="0.6rem"
                        alignItems="center"
                        justifyContent={["center", null]}
                      >
                        <h4>
                          <SmallNumber>$1000 / 4 weeks</SmallNumber>
                        </h4>
                      </Box>
                      <Text
                        fontSize="14px"
                        fontWeight="bold"
                        color={color.white}
                        mb=".6rem"
                        textAlign="center"
                      >
                        LIMITED TIME
                      </Text>
                    </>
                  <Box
                    display="flex"
                    mb="0.6rem"
                    alignItems="center"
                    justifyContent={["center", null]}
                  >
                    <h4>
                      <Dollar>$ </Dollar>
                      <Number>
                        {urlParameters.query?.offer ? "695" : "1000"}
                      </Number>{" "}
                      <Extra>/ 4 weeks</Extra>
                    </h4>
                  </Box>

                  <Text color="white" variant="small">
                    Billed in 12 week cycles
                  </Text>
                </Box>
              </Box>
            </Box>
          </Width>
        </Box> */}
        {/* Mentor Section One */}
        <Box
          bg={color.opal_hover}
          variant="slopeTopAndBottom"
          mt="-3.6rem"
          pb="6rem"
        >
          <Heading textAlign="center" py="3.6rem">
            Meet our mentors
          </Heading>
          <Width>
            <Box
              display="grid"
              gridTemplateColumns={["1fr", null, "1fr 1fr", null]}
              gridGap={["3.8rem", null, "6rem 2rem", null]}
              mx="auto"
              width={["100%", "80%", 10 / 12, null]}
            >
              {/* Individual Mentors */}
              <MentorCard
                name="Adam Levy"
                slug="adam-levy"
                image={data.adamLevyImage.childImageSharp.fluid}
              >
                Brooklyn-based Adam Levy has released more than a dozen albums
                of his own, and is featured on recordings by Norah Jones,
                Vulfpeck, Ani DiFranco, Allen Toussaint, and other renowned
                artists.
              </MentorCard>
              <MentorCard
                name="Steve Cardenas"
                slug="steve-cardenas"
                image={data.steveCardenasImage.childImageSharp.fluid}
              >
                Brooklyn-based jazz guitarist Steve Cardenas has recorded and
                toured with such jazz greats as Paul Motian, Charlie Haden and
                Steve Swallow, to name a few. Steve is also on faculty at The
                New School for Jazz and Contemporary Music in New York City.
              </MentorCard>
              <MentorCard
                name="Amanda Monaco"
                slug="amanda-monaco"
                image={data.amandaMonacoImage.childImageSharp.fluid}
              >
                Amanda is an Associate Professor at Berklee College of Music
                where she teaches private instruction, labs and guitar
                ensembles. and has performed with artists such as Milt Hinton,
                Steve Wilson, Rufus Reid, and more.
              </MentorCard>

              <MentorCard
                name="Arianna Powell"
                slug="arianna-powell"
                image={data.ariannaPowellImage.childImageSharp.fluid}
              >
                Arianna Powell is a Los Angeles-based touring and session
                guitarist. She currently plays guitar for Halsey and has also
                toured with Nick Jonas, Black Eyed Peas, JJ Lin, Drake Bell, and
                more.
              </MentorCard>
              <MentorCard
                name="Mimi Fox"
                slug="mimi-fox"
                image={data.mimiFoxImage.childImageSharp.fluid}
              >
                Internationally renowned guitarist, composer, and recording
                artist Mimi Fox has worked with artists Abbey Lincoln, Joey
                DeFrancesco, Charlie Byrd, Branford Marsalis, Diana Krall, and
                Stevie Wonder, and more.
              </MentorCard>
              <MentorCard
                name="Brian Green"
                slug="brian-green"
                image={data.brianGreenImage.childImageSharp.fluid}
              >
                Brian Green is a guitar player and producer based out of Los
                Angeles, California. He has toured, performed and/or recorded
                with artists including John Legend, Rufus Wainwright, Michael
                Bublé, Nayo, and Kat Mcphee.
              </MentorCard>

              <MentorCard
                name="Mark Whitfield"
                slug="mark-whitfield"
                image={data.markWhitfieldImage.childImageSharp.fluid}
              >
                Mark Whitfield is a Berklee-trained jazz guitarist who has
                collaborated with legendary artists including Dizzy Gillespie,
                Art Blakey, Quincy Jones, Ray Charles, Herbie Hancock, and more.
              </MentorCard>

              <MentorCard
                name="Josh Sklair"
                slug="josh-sklair"
                image={data.joshSklairImage.childImageSharp.fluid}
              >
                Josh Sklair is a two-time Grammy Award winner, honored during
                his 20+ years collaboration as band leader, guitarist, arranger
                and co-writer with American Music icon Etta James.
              </MentorCard>
            </Box>
          </Width>
        </Box>

        {/* Mentor Section Two */}
        <Box bg={color.opal_hover} mt="-3.6rem" mb="-3.6rem" pb="6rem">
          <Width pt="1.8rem">
            <Box
              display="grid"
              gridTemplateColumns={["1fr", null, "1fr 1fr", null]}
              gridGap={["3.8rem", null, "6rem 2rem", null]}
              mx="auto"
              width={["100%", "80%", 10 / 12, null]}
            >
              {/* Individual Mentors */}
              <MentorCard
                name="Jordan Peters"
                slug="jordan-peters"
                image={data.jordanPetersImage.childImageSharp.fluid}
              >
                Brooklyn based guitarist and producer, Jordan Peters has worked,
                toured, performed and recorded with artists such as Lauryn Hill,
                Renée Elise Goldsberry, Jon Batiste, The Weeknd, NAS, Billy
                Porter, Quiñ, The Roots, and more.
              </MentorCard>
              <MentorCard
                name="Dr. Molly Miller"
                slug="dr-molly-miller"
                image={data.mollyMillerImage.childImageSharp.fluid}
              >
                Dr. Miller is the Chair of the Guitar Department at Los Angeles
                College of Music. She’s also one of LA’s most sought-after
                musicians, recording and touring with artists such as Jason
                Mraz, Black Eyed Peas, and more.
              </MentorCard>
              <MentorCard
                name="Horace Bray"
                slug="horace-bray"
                image={data.horaceBrayImage.childImageSharp.fluid}
              >
                Horace is an LA-based touring and session musican, who plays
                with artists like India Arie, Jordan Fisher, Taylor Dayne, Sofia
                Carson, Sam Fischer, John Splithoff, Michael Blume, Abir, and
                more.
              </MentorCard>
              <MentorCard
                name="Curt Henderson"
                slug="curt-henderson"
                image={data.curtHendersonImage.childImageSharp.fluid}
              >
                Nashville-based Curt Henderson has played, recorded, and toured
                internationally with major artists like EZA (Ellery Bonham),
                John King, and worked with American Idol finalist Jonny Brenns
              </MentorCard>
              <MentorCard
                name="Nicholas Veinoglou"
                slug="nicholas-veinoglou"
                image={data.nicholasVeinoglouImage.childImageSharp.fluid}
              >
                Nicholas is an LA-based session and touring musician who has
                toured both nationally and internationally with artists like
                Jordan Fisher and Atlantic Records recording artist Bazzi.
              </MentorCard>
              <MentorCard
                name="Nora Bite"
                slug="nora-bite"
                image={data.noraBiteImage.childImageSharp.fluid}
              >
                Jazz guitarist Nora Bite is a London-based educator and composer
                from Latvia. One of the many artists Nora has had the
                opportunity to share the stage with includes the great, late Amy
                Winehouse.
              </MentorCard>
              <MentorCard
                name="Robb Cappelletto"
                slug="robb-cappelletto"
                image={data.robbCappellettoImage.childImageSharp.fluid}
              >
                Robb Cappelletto is a guitarist, composer and educator based in
                Toronto, Canada. He has two decades of professional teaching
                experience and has been on faculty at York University as a jazz
                guitar instructor since 2007.
              </MentorCard>
              <MentorCard
                name="Rotem Sivan"
                slug="rotem-sivan"
                image={data.rotemSivanImage.childImageSharp.fluid}
              >
                Rotem Sivan’s guitar skills are heralded across the globe. The
                New York Times has praised him as “more than a very good young
                guitar player.” Downbeat Magazine has called him “a remarkable
                talent.
              </MentorCard>
              <MentorCard
                name="TJ Whitelaw"
                slug="tj-whitelaw"
                image={data.tjWhitelawImage.childImageSharp.fluid}
              >
                Tj is a Toronto-based touring and session guitarist. His
                on-stage experience is wide-ranging, spanning from Pop/country
                to jazz to Gospel. He’s performed with MYA, Jessie Reyez,
                Madison Beer, Kardinal Offishall, and more.
              </MentorCard>
              <MentorCard
                name="Alicyn Yaffee"
                slug="alicyn-yaffee"
                image={data.alicynYaffeeImage.childImageSharp.fluid}
              >
                Alicyn Yaffee is a New York City-based guitarist, vocalist and
                bassist, She has toured the U.S. and Europe under her own name,
                as well as with Becca Stevens, Grammy-nominee JC Hopkins’
                Biggish Band, and bassist Benny Rietveld (Santana).
              </MentorCard>
            </Box>
          </Width>
        </Box>
      </Box>
    </Layout>
  )
}

/* Image and Other GQL Queries */
export const mentorshipQuery = graphql`
  query {
    adamLevyImage: file(relativePath: { eq: "adamLevy.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    amandaMonacoImage: file(relativePath: { eq: "amandaMonaco.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    ariannaPowellImage: file(relativePath: { eq: "ariannaPowell.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    beccaStevensImage: file(relativePath: { eq: "beccaStevens.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    brianGreenImage: file(relativePath: { eq: "brianGreen.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    curtHendersonImage: file(relativePath: { eq: "curtHenderson.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    horaceBrayImage: file(relativePath: { eq: "horaceBray.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    jeffParkerImage: file(relativePath: { eq: "jeffParker.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    jordanPetersImage: file(relativePath: { eq: "jordanPeters.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    joshSklairImage: file(relativePath: { eq: "joshSklair.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    markWhitfieldImage: file(relativePath: { eq: "markWhitfield.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    mimiFoxImage: file(relativePath: { eq: "mimiFox.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    mollyMillerImage: file(relativePath: { eq: "mollyMiller.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    nicholasVeinoglouImage: file(
      relativePath: { eq: "nicholasVeinoglou.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    noraBiteImage: file(relativePath: { eq: "noraBite.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    robbCappellettoImage: file(relativePath: { eq: "robbCappelletto.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    rotemSivanImage: file(relativePath: { eq: "rotemSivan.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    ryanLermanImage: file(relativePath: { eq: "ryanLerman.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    steveCardenasImage: file(relativePath: { eq: "steveCardenas.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    tjWhitelawImage: file(relativePath: { eq: "tjWhitelaw.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    alicynYaffeeImage: file(relativePath: { eq: "alicynYaffee.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    communityImage: file(relativePath: { eq: "community.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    curriculumImage: file(relativePath: { eq: "curriculum.png" }) {
      childImageSharp {
        fluid(maxWidth: 570, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`

const Number = styled.span`
  font-size: 4.2rem;
  line-height: 1.16;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
`

const SmallNumber = styled.span`
  font-size: 2.2rem;
  text-decoration: line-through;
  line-height: 1.16;
  opacity: 0.5;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
`

const Extra = styled.span`
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.16;
  color: ${({ theme }) => theme.colors.purpley};
`

const Dollar = styled.span`
  font-weight: 300;
  font-size: 3.4rem;
  line-height: 1.16;
  letter-spacing: 0.01em;
  color: rgba(179, 168, 203, 0.5);
`
